import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import {from, Observable, throwError} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {AuthB2cService} from "./authb2c.service";
//import {switchMap} from "rxjs-compat/operator/switchMap";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authService: AuthB2cService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          console.log('Error no autorizado. Renovación de token y reintento de solicitud...');
          // Renovar el token
          return from(this.authService.renewToken()).pipe(
            switchMap(() => {
              // Clonar la solicitud original y actualizar el encabezado de autorización con el nuevo token
              const token = this.authService.getAccessToken();
              const authRequest = request.clone({
                setHeaders: {
                  Authorization: `Bearer ${token}`
                }
              });
              // Reintentar la solicitud original con el nuevo token
              return next.handle(authRequest);
            }),
            catchError((renewError: HttpErrorResponse) => {
              console.error('Error al renovar el token:', renewError);
              return throwError(renewError); // Re-lanzar el error si falla la renovación del token
            })
          );
        }
        // Si el error no es Unauthorized, re-lanzar el error original
        return throwError(error);
      })
    );
  }
}
