export const Global = {
  //url: 'http://localhost:49445/api/',
  //API: 'http://localhost:49445/api/',
  UrlRedirect: 'http://localhost:4200/authentication/login',
  XMS_TEST: 'https://apim.skretting.com/paycom/v3/applications/39/companies/560/user-permissions',//https://xmsapitest.skretting.com/v3/account/authorizeclaim
  //XMS_USERS: 'https://apimtest.skretting.com/sam/v3/applications/45/users',
  XMS_USERS: 'https://apim.skretting.com/paycom/v3/applications/243/users',


  // Identity server
  /**/
  redirect_uri: "https://paycom.skrettingdigital.com/callback",
  post_logout_redirect_uri: "https://paycom.skrettingdigital.com",
  silent_redirect_uri: 'https://paycom.skrettingdigital.com/silent-refresh',

  // LocalHost
  /*
  redirect_uri: "http://localhost:4200/callback",
  post_logout_redirect_uri: "http://localhost:4200",
  silent_redirect_uri: 'http://localhost:4200/silent-refresh',*/

  authority: "https://aquasimlogin.skretting.com",
  client_id: "Paycom",
  scope: "openid profile AquaSim offline_access apiApp",
  response_type: "code",
  acr_values: 'idp:azureadb2c-paycom',

  // user profile session storage
  key_user: "oidc.user:https://aquasimlogin.skretting.com:Paycom",
  //key_user: "oidc.user:https://aquasimlogintest.skretting.com:Skomex",


  // permission from XMS
  permissions_user: 'permissions',//"permissions_user",


  // User
  API_SET_USER_LOG: 'auth/SetUserLogged',
  API_GET_USER_LOG: 'User/UserLogged',
  API_GET_USER_ALL: 'User/GetUsers',
  API_GET_USER_INT: 'User/GetUsersInternal',
  API_GET_USER_NASS: 'User/GetUsersByExternalUnassigned',
  API_GET_USER_COMB: 'User/GetUsersByCombinated/',
  API_GET_USER_CODE: 'User/UserCode/',
  API_UPDATE_USER_LIST: 'User/UpdateUserList/',
  API_GET_USER_SIGNATURE: 'User/AuthorizedSignature/',
  API_GET_COUNTRY_BY_USER: 'User/GetCountryByUser/',
  API_GET_LIST_USER: 'User/GetListUser',






  //Parameters
  MiliSeconds: 1000,
  Seconds: 3600,
  Minutes: 60,
  Hour: 1,
  MiliSecondsToHour: 3600000,
  MicrosecondstoMinute: 60000000,

  COMPANY_ID: 237,
  USER_LIST_SUB: ['4077', '4249', '4252', '4253', '4254', '4289', '3843'],
  // Listado de Usuarios Maestros
  // 4077: Andy Sanchez
  // 4249: Ronald Sevilla
  // 4289: Paul Santillan
  // 3843: Marco Castro

 // 4252 - 4253 - 4254: NO



  PERMISSIONS: [

    /*
    // PURCHASE
    {id: 'PURCHASE_ORDER', name: "CX_PO"},
    {id: 'PURCHASE_ORDER_LINE', name: "CX_POL"},

    // MAINTENANCE
    {id: 'ALERT', name: "CX_AL"},
    {id: 'CUSTOM_AGENT', name: "CX_CA"},
    {id: 'CUSTOM_AGENT_EMPLOYEE', name: "CX_CAE"},
    {id: 'DOCUMENT', name: "CX_DOC"},
    {id: 'LIBERATORY_CODE', name: "CX_LC"},
    {id: 'OBSERVATION', name: "CX_OB"},
    {id: 'PACKAGING', name: "CX_PCK"},
    {id: 'PARAMETER', name: "CX_PRM"},


    {id: 'PERMISSION', name: "CX_PRS"},
    {id: 'PURCHASE_ORDER_ASSIGN', name: "CX_POA"},
    {id: 'PURCHASE_ORDER_LINE_ASSIGN', name: "CX_POLA"},
    {id: 'PURCHASE_ORDER_LINE_DETAIL_ASSIGN', name: "CX_POLDA"},
    {id: 'STATUS', name: "CX_ST"},
    {id: 'TRANSPORT_COMPANY', name: "CX_TC"},
    {id: 'TRANSPORT_COMPANY_EMPLOYEE', name: "CX_TCE"},
    {id: 'WAREHOUSE', name: "CX_WH"},
    */





    // MENU

    { id: 'CERTF', name: "SQ_CERTF" },                                    // 1. Certificaciones

    { id: 'MONITORING', name: "SQ_MONITORING" },                              // 1.1 Seguimientos

    { id: 'REGISTERS', name: "SQ_REGISTERS" },                                // 1.2 Registros Sanitarios

    { id: 'DECLARATIONS', name: "SQ_DECLARATIONS" },                          // 1.3 Declaraciones
        { id: 'ASC', name: "SQ_DECLARATIONS_ASC" },                                 // 1.3.1 Declaraciones ASC
        { id: 'SUSTANCES', name: "SQ_DECLARATIONS_SUSTANCES" },                     // 1.3.2 Sustancias No Prohibidas

    { id: 'ENVIRONMENT', name: "SQ_ENVIRONMENT" },                            // 1.4 Medio Ambiente
        { id: 'WAST', name: "SQ_WAST" },                                          // 1.4.1 Desechos
        { id: 'CONSUMPTION', name: "SQ_CONSUMPTION" },                            // 1.4.2 Consumos
              { id: 'CONSUMPTION_WAST', name: "SQ_CONSUMPTION_WAST" },                  // 1.4.2.1 Consumos - Desechos
              { id: 'CONSUMPTION_WATER', name: "SQ_CONSUMPTION_WATER" },                // 1.4.2.2 Consumos - Agua
              { id: 'CONSUMPTION_FUEL', name: "SQ_CONSUMPTION_FUELS" },                 // 1.4.2.3 Consumos - Combustible
              { id: 'CONSUMPTION_ENERGY', name: "SQ_CONSUMPTION_ENERGY" },              // 1.4.2.4 Consumos - Energua

    { id: 'CERTIFICATES_QUALITY', name: "SQ_CERTIFICATES_QUALITY" },         // 1.5 Certificados de Calidad
        { id: 'QUALITY_AQUA', name: "SQ_CERTIFICATES_AQUA" },                    // 1.5.1 Certificados Aqua
        { id: 'QUALITY_PECUARIO', name: "SQ_CERTIFICATES_PECUARIO" },                    // 1.5.1 Certificados Pecuario


    { id: 'ASSURANCE', name: "SQ_ASSURANCE" },        // Aseguramiento

        { id: 'PROCESS_CONTROL', name: "SQ_PROCESS_CONTROL" },
        { id: 'NON_CONFORMING', name: "SQ_NON_CONFORMING" },
        { id: 'REPROCESSES', name: "SQ_REPROCESSES" },
        { id: 'CLAIMS', name: "SQ_CLAIMS" },
        { id: 'EXPIRED', name: "SQ_EXPIRED" },


    { id: 'LAB', name: "SQ_LAB" },                    // Laboratorio
        { id: 'REQUEST_ANALYSIS', name: "SQ_REQUEST_ANALYSIS" },
        { id: 'SAMPLES', name: "SQ_SAMPLES" },

    { id: 'MANT', name: "SQ_MANT" },                  // Mantenimientos
        { id: 'PARAMETERS', name: "SQ_PARAMETERS" },      // Parámetros
        { id: 'TREATMENTS', name: "SQ_TREATMENTS" },      // Tratamientos
        { id: 'ANALYSIS', name: "SQ_ANALYSIS" },          // Análisis
        { id: 'IMAGES', name: "SQ_IMAGES" },              // Imágenes
        { id: 'ASC_DOC', name: "SQ_ASC_DOC" },            // Documentos Asc
        { id: 'LABORATORIES', name: "SQ_LABORATORIES" },  // Laboratorios
        { id: 'CLIENTS', name: "SQ_CLIENTS" },            // Clientes
        { id: 'SPECIES', name: "SQ_SPECIES" },            // Especies
        { id: 'USERS', name: "SQ_USERS" },                    // Users
        { id: 'MICROBOLOGICAL', name: "SQ_MICROBOLOGICAL"},                // Microbiología
        { id: 'CONSUMPTION_PARAMETER', name: "SQ_CONSUMPTION_PARAMETER"},  // Consumption Parameter

    ///microbological







  ]
};
