import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../environments/environment';
import {SwUpdate} from '@angular/service-worker';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'ca2';
  version: any;

  constructor(private translate: TranslateService, private swUpdate: SwUpdate) {
    const userLang = 'es';
    translate.setDefaultLang(userLang);
    translate.use(userLang);
  }

  ngOnInit() {
    this.version = environment.version;

    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        const upd_msg = 'New version available. Load New Version?';

        if (confirm(upd_msg)) {
          window.location.reload();
        }
      });
    }
  }
}
