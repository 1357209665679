import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Ca2RoutingModule } from './ca2-routing.module';

import { FullComponent } from './layouts/full/full.component';

import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { BreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component';
import { HeaderNavigationComponent } from './shared/header-navigation/header-navigation.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import {TranslateModule} from '@ngx-translate/core';
import { UserComponent } from './maint/user/user.component';
import { CustomergroupsComponent } from './maint/customergroups/customergroups.component';
import { CustomersComponent } from './maint/customers/customers.component';
import { ProductlinesComponent } from './maint/productlines/productlines.component';
import { BaseCrudComponent } from './base-crud/base-crud.component';
import {MatTableModule} from '@angular/material';
import {OrderModule} from 'ngx-order-pipe';
import {NgbModule, NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule} from '@angular/forms';
import { SublineComponent } from './maint/subline/subline.component';
import { ProductsComponent } from './maint/products/products.component';
import { AgentsComponent } from './maint/agents/agents.component';
import { AgenttypesComponent } from './maint/agenttypes/agenttypes.component';
import { ParameterComponent } from './maint/parameter/parameter.component';
import { ParamsComponent } from './maint/params/params.component';
import { AgentgroupsComponent } from './maint/agentgroups/agentgroups.component';
import { CommissiontableComponent } from './maint/commissiontable/commissiontable.component';
import {NgMultiSelectDropDownModule} from "ng-multiselect-dropdown";
import { ExceptionhandlingComponent } from './maint/exceptionhandling/exceptionhandling.component';
import {OnlyNumber} from '../onlynumber.directive';
import { ExtractDataComponent } from './process/extract-data/extract-data.component';
import { GenerateCommissionComponent } from './process/generate-commission/generate-commission.component';
import { CommissionDetailComponent } from './process/commission-detail/commission-detail.component';
import { HierarchicalComponent } from './maint/hierarchical/hierarchical.component';
import { ComparativeComponent } from './report/comparative/comparative.component';
import { CashCreditComponent } from './report/cash-credit/cash-credit.component';
import { CashCreditDetailComponent } from './report/cash-credit-detail/cash-credit-detail.component';
import { ExceedanceRuleComponent } from './maint/exceedance-rule/exceedance-rule.component';
import { AuthCallbackComponent } from './redirectS/auth-callback/auth-callback.component';
import { SilentRefreshComponent } from './redirectS/silent-refresh/silent-refresh.component';


@NgModule({
  declarations: [FullComponent, SidebarComponent, BreadcrumbComponent, HeaderNavigationComponent, AuthCallbackComponent, DashboardComponent, UserComponent, CustomergroupsComponent, CustomersComponent, ProductlinesComponent, BaseCrudComponent, SublineComponent, ProductsComponent, AgentsComponent, AgenttypesComponent, ParameterComponent, ParamsComponent, AgentgroupsComponent, CommissiontableComponent, ExceptionhandlingComponent, OnlyNumber, ExtractDataComponent, GenerateCommissionComponent, CommissionDetailComponent, HierarchicalComponent, ComparativeComponent, CashCreditComponent, CashCreditDetailComponent, ExceedanceRuleComponent, SilentRefreshComponent],
    imports: [
        CommonModule,
        Ca2RoutingModule,
        TranslateModule,
        MatTableModule,
        OrderModule,
        NgbPaginationModule,
        FormsModule,
        NgMultiSelectDropDownModule,
        NgbModule
    ]
})
export class Ca2Module { }
