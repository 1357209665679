import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: 'dashboard',
    title: 'menu_home',
    icon: 'fa fa-home',
    class: '',
    extralink: false,
    perm: 'home',
    submenu: []
  },
  {
    path: 'cruds',
    title: 'menu_crud',
    icon: 'fa fa-edit',
    class: '',
    extralink: true,
    perm: 'CA_MANT',
    submenu: [{title: 'menu_exception_handling', path: '/exceptionhandling', icon: 'mdi mdi-adjust', perm: 'CA_EXCEPTIONS_HANDLING', class: '', extralink: false, submenu: []},
      {title: 'menu_commission_tables', path: '/commissiontables', icon: 'mdi mdi-adjust', perm: 'CA_FINE', class: '', extralink: false, submenu: []},
      {title: 'menu_agent_groups', path: '/agentgroups', icon: 'mdi mdi-adjust', perm: 'CA_AGENTS_GROUP', class: '', extralink: false, submenu: []},
      {title: 'parameters', path: '/parameters', icon: 'mdi mdi-adjust', perm: 'CA_PARAMETERS', class: '', extralink: false, submenu: []},
      {title: 'menu_profile', path: '/hierarchical', icon: 'mdi mdi-adjust', perm: 'CA_HIERARCHICAL_PROFILE', class: '', extralink: false, submenu: []},
      {title: 'menu_agenttype', path: '/agenttype', icon: 'mdi mdi-adjust', perm: 'CA_AGENTS_TYPE', class: '', extralink: false, submenu: []},
      {title: 'menu_agent', path: '/agent', icon: 'mdi mdi-adjust', perm: 'CA_AGENTS', class: 'line-separator-b', extralink: false, submenu: []},
      {title: 'menu_users', path: '/users', icon: 'icon icon-people', perm: 'CA_USERS', class: 'line-separator-b', extralink: false, submenu: []},
      {title: 'menu_customergroup', path: '/customergroup', icon: 'mdi mdi-adjust', perm: 'CA_CUSTOMER_GROUP', class: '', extralink: false, submenu: []},
      {title: 'menu_customer', path: '/customer', icon: 'mdi mdi-adjust', perm: 'CA_CUSTOMER', class: '', extralink: false, submenu: []},
      {title: 'menu_productline', path: '/productline', icon: 'mdi mdi-adjust', perm: 'CA_PRODUCT_LINE', class: '', extralink: false, submenu: []},
      {title: 'menu_subline', path: '/subline', icon: 'mdi mdi-adjust', perm: 'CA_PRODUCT_SUBLINE', class: '', extralink: false, submenu: []},
      {title: 'menu_product', path: '/product', icon: 'mdi mdi-adjust', perm: 'CA_PRODUCT', class: '', extralink: false, submenu: []},
      {title: 'menu_exceedanceRule', path: '/exceedanceRule', icon: 'mdi mdi-adjust', perm: 'CA_EXCEEDANCERULES', class: '', extralink: false, submenu: []}
      ]
  },
  {
    path: 'prochome',
    title: 'menu_processes',
    icon: 'fa fa-desktop',
    class: '',
    extralink: true,
    perm: 'CA_PROC',
    submenu: [{title: 'menu_get_data', path: '/extractdata', icon: 'mdi mdi-adjust', perm: 'CA_GET_SALES', class: '', extralink: false, submenu: []},
      {title: 'menu_commission_process', path: '/commissionprocess', icon: 'mdi mdi-adjust', perm: 'CA_COMMISSION_PROCESS', class: '', extralink: false, submenu: []}]
  },
  {
    path: 'formshome',
    title: 'menu_reports',
    icon: 'fa fa-table',
    class: '',
    extralink: true,
    perm: 'CA_REPORT',
    submenu: [{title: 'menu_comparative', path: '/comparative', icon: 'mdi mdi-adjust', perm: 'CA_COMPARATIVE', class: '', extralink: false, submenu: []},
      {title: 'menu_cashcredit', path: '/cashcredit', icon: 'mdi mdi-adjust', perm: 'CA_CASHCREDIT', class: '', extralink: false, submenu: []}]
  }/*,
  {
    path: '/settings',
    title: 'menu_general',
    icon: 'fa fa-settings',
    class: '',
    extralink: true,
    perm: 'sett',
    submenu: []
  }*/
];
