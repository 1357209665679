import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {AuthService} from '../../../providers/account/auth.service';
import { Router } from '@angular/router';
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { Storage } from '@ionic/storage';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import * as $ from "jquery";
import {of, Observable} from "rxjs";
import {catchError} from "rxjs/operators";
import Swal from "sweetalert2";

import {UserCx} from "../../../interfaces/User";
import {AuthB2cService} from "../../../providers/account/authb2c.service";
@Component({
  selector: 'app-header-navigation',
  templateUrl: './header-navigation.component.html',
  styleUrls: ['./header-navigation.component.css']
})
export class HeaderNavigationComponent implements OnInit {
  @Output() toggleSidebar = new EventEmitter<void>();
  public user$: Observable<UserCx>;

  public user: any;
  public username:any;
  public country: any;
  opcionSeleccionado: string  = 'EC';
  public config: PerfectScrollbarConfigInterface = {};

  constructor(
    private modalService: NgbModal,
    private _router: Router,
    private storage: Storage,
    private srv: AuthService,
    private _authService: AuthB2cService
  ) {
    storage.get('username').then(x => {
      this.user = x;
    });
    storage.get('user_name').then(x=> {
      this.username = x;
    });
    storage.get('current_country').then(x => {
      this.opcionSeleccionado=x;
    });
    //this.storage.set('current_country', data['CurrentCountry'])
  }

  ngOnInit() {
    this.user$ = this._authService.UserObservable;
  }

  toggleClicked(event: MouseEvent) {
    var target = event.target["id"];
    var body = $("body");
    var menu = $("#sidebar-menu");

    // toggle small or large menu
    if (body.hasClass("nav-md")) {
      menu.find("li.active ul").hide();
      menu
        .find("li.active")
        .addClass("active-sm")
        .removeClass("active");
    } else {
      menu.find("li.active-sm ul").show();
      menu
        .find("li.active-sm")
        .addClass("active")
        .removeClass("active-sm");
    }
    body.toggleClass("nav-md nav-sm");
  }

  public logout() {
    this.srv.clearData().then(() => {
      this._router.navigate(['/account/auth']);
    });
  }
  public logout2() {
    this._authService.logout();
  }
  public changeCountry(type){
    const login = {
      Username: this.username,
      Password: type
    };
    //this.isLoading = true;
    this.srv.saveCountry(login).subscribe((rest) => {
      console.log(rest);
      // this.isLoading = false;
      // HealthAdvisor
      this.storage.set('current_country', type);
    }, (err) => {
      //this.isLoading = false;
      if (err.status === 0) {
        Swal;
        Swal.fire('¡Ups!', 'Tuvimos un inconveniente, inténtalo nuevamente.', 'warning');
      }
      else if (err.status === 401) {
        Swal.fire('Inconveniente al actualizar.', 'Por favor vuelva a seleccionar el pais.', 'error');
      }
    });
    /**/
  }
}
