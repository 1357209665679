import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Global } from '../global';
import { Router } from '@angular/router';
import { from, of } from 'rxjs';
import { mergeMap, catchError, timeout, retry } from 'rxjs/operators';
import { Storage } from '@ionic/storage';
//import { OfflineContainer } from '../offlinecontainer';
import { Response } from 'src/app/interfaces/Response';
// max time for api requests
const MAX_TIME = 60000;

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  url: any;

  constructor(
    private _router: Router,
    private http: HttpClient,
    public storage: Storage
  ) {
    this.url = Global.url;
  }

  public getCall(qst) {
    // console.log('get call');
    // console.log(this.storage.get('token'));
    const resp = {success: false, msg: '', val: []};
    return from(this.storage.get('token')).pipe(
      mergeMap(token => {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`});
        return this.http.get(`${this.url}` + qst, { headers })
          .pipe(
            mergeMap(data => {
              let mydata: any = {STATUS: '', OBJETO: [], MENSAJE: ''};
              mydata = data;

              if ( mydata.STATUS === 'success' ) {
                // return of(mydata.OBJETO);
                resp.success = true;
              }

              resp.msg = mydata.MENSAJE;
              resp.val = mydata.OBJETO;

              return of(resp);
            }),
            timeout(MAX_TIME),
            catchError(err => {
              resp['msg'] = 'system error';
              return of(resp);
            })
          );
      })
    );
  }

  public postCall(qst, obj) {
    // console.log('cool post call');
    const resp = {success: false, msg: '', val: []};
    return from(this.storage.get('token')).pipe(
      mergeMap(token => {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`});
        // console.log('before post');
        return this.http.post(`${this.url}` + qst, obj, { headers })
          .pipe(
            mergeMap(data => {
              let mydata: any = {STATUS: '', OBJETO: [], MENSAJE: ''};
              mydata = data;

              if ( mydata.STATUS === 'success' ) {
                // return of(mydata.OBJETO);
                resp.success = true;
              }

              resp.msg = mydata.MENSAJE;
              resp.val = mydata.OBJETO;

              return of(resp);
            }),
            timeout(MAX_TIME),
            catchError(err => {
              resp['msg'] = 'system error';
              return of(resp);
            })
          );
      })
    );
  }

  public persistData(key: string, val: any) {
    return this.storage.set(key, val);
  }

  public getPersistedData(key: string) {
    return this.storage.get(key);
  }
  getCountry(){
    return this.storage.get('current_country');
  }
  getUser(){
    return this.storage.get('user_name');
  }
  getStatus(){
    return this.storage.get('is_approver');
  }
  getProfile(){
    return this.storage.get('position');
  }
  public getBlobCall(qst) {
    return from(this.storage.get('token')).pipe(
      mergeMap(token => {
        const headers = new HttpHeaders({ 'Authorization': `Bearer ${token}`});
        return this.http.get(`${this.url}` + qst, { headers, responseType: 'blob' })
          .pipe(
            mergeMap(data => {
              // console.log(data);
              return of(data);
              // return of(true);
            }),
            timeout(MAX_TIME),
            catchError(err => {
              return of(false);
            })
          );
      })
    );
  }

  public post(qst, obj) {
    let resp: Response = {
      val:'',
      success:'',
      Status: false,
      Object: null,
      Message: '',
      Code: ''
    };
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(`${this.url}` + qst, obj, { headers })
      .pipe(
        mergeMap(data => {
          let mydata: any = {STATUS: '', OBJETO: [], MENSAJE: ''};
          mydata = data;
          if (mydata.STATUS == 'success') {
            resp.Status = true;
          }
          resp.Message = mydata.MENSAJE;
          resp.Object = mydata.OBJETO;
          resp.Code = mydata.STATUS;
          return of(resp);
        }),
        timeout(MAX_TIME),
        catchError(err => {
            resp.Message = 'system error, ' + err;
            return of(resp);
          }
        )
      );
  }

  public get(qst) {
    let resp: Response = {
      val:'',
      success:'',
      Status: false,
      Object: null,
      Message: '',
      Code: ''
    };
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get(`${this.url}` + qst, { headers })
      .pipe(
        mergeMap(data => {
          let mydata: any = null;
          mydata = data;
          if (mydata.Status == 'success') {
            resp.Status = true;
          }
          resp.Message = mydata.Message;
          resp.Object = mydata.Object;
          resp.Code = mydata.Code;
          return of(resp);
        }),
        timeout(MAX_TIME),
        catchError(err => {
            resp.Message = 'system error, ' + err;
            return of(resp);
          }
        )
      );
  }
}
