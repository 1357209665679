import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from '../../providers/account/auth.service';
import * as $ from "jquery";
import {ApiService} from "../../providers/main/api.service";
import {BehaviorSubject, concat, of} from "rxjs";
import {catchError, mergeMap} from "rxjs/operators";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  translations:any;
  currlang:any;
  version:any;

  permissions = [];
  isApprover:any;
  agentCode:any;
  profile:any;
  currentUser:any;
  rows: any;
  periodList:any;
  backPage:any;
  public reload = new BehaviorSubject(false);
  constructor(
    public _router: Router,
    public translate: TranslateService,
    public auth: AuthService
    ,public srv: ApiService
  ) {
    this.reload.subscribe(val => {
      if( val ){

        this.getList();
      }
    });
  }

  ngOnInit() {

    this.auth.getPermissions().then(x => {
      this.permissions = x;
      this.permissions.push('sett');
    });
    this.auth.getUser().then(x=>{
      this.currentUser = x;
    });
    this.auth.obtenerEsAprobador().then(x => {
      this.isApprover=x;
    });
    this.auth.obtenerPerfil().then(x => {
      this.agentCode=x;
    });
    this.auth.obtenerPerfil().then(x=>{
      this.profile = x;
    });

    var body = $("body");
    if (body.hasClass("login")) {
      body
        .addClass("nav-md")
        .removeClass("login");
    }
    this.translate.getTranslation(this.translate.currentLang)
      .subscribe((translations) => {
        this.translations = translations;

        var batch = [];
        batch.push(this.getPeriodsAprove());
        //batch.push(this.getParameters());
        //this.loadingList = true;
        concat(...batch).subscribe({
          complete: () => {
            //this.loadingList = false;
            //this.getParameters();
            this.getList();
          }
        });
      });
    //this.getList();
  }
  public getPeriodsAprove(){
    let url = 'CommissionResult/GetCommissionApprovedList';
    return this.srv.getCall(url).pipe(
      mergeMap(data => {
        let mydata:any = {success: false, msg:'', val:[]};
        mydata = data;
        //debugger;
        if(mydata.success == true ){
          this.periodList = mydata.val;
          return of(true);
        }
        else{
          return of(false);
        }
      }),//timeout(MAX_TIME),
      catchError(data => of(false))
    );
  }
  public getParameters(){

    debugger;
  }
  public getList(){
    var qst='';
    if (this.profile==1){
      qst = 'CommissionResult/GetCommissionByApprove2';//+this.currentUser;
    }else if (this.agentCode>20){
      qst = 'CommissionResult/GetCommissionByApprove3';
      this.backPage='dashboard';
    }else{
      qst = 'CommissionResult/GetCommissionByApprove';
    }

    //this.loadingList = true;
    this.srv.getCall(qst).subscribe(x => {
      //this.loadingList = false;
      debugger;
      if(x.success){
        /*x.val.forEach(y => {
          y['name'] = y['FirstName'] + ' ' + y['LastName'];
        });*/

        //this.rawdata = x.val;
        this.periodList = x.val;
        //this.page = 1;
        console.log(this.rows);
        if( Array.isArray(this.rows) ){
          //this.pageTotal = this.rows.length;
        }
      }
    });
  }
  hasPermission(str:string){
    if( this.permissions.indexOf(str) > -1 ){
      return true;
    }
    else{
      return false;
    }
  }

}
