import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AuthService} from '../providers/account/auth.service';
import {DashboardComponent} from './dashboard/dashboard.component';
import {FullComponent} from './layouts/full/full.component';
import { UserComponent } from './maint/user/user.component';
import {AgentsComponent} from './maint/agents/agents.component';
import {ProductlinesComponent} from './maint/productlines/productlines.component';
import {CustomersComponent} from './maint/customers/customers.component';
import {CustomergroupsComponent} from './maint/customergroups/customergroups.component';

import { SublineComponent } from './maint/subline/subline.component';
import {ProductsComponent} from './maint/products/products.component';
import {AgenttypesComponent} from './maint/agenttypes/agenttypes.component';
import { ParameterComponent } from './maint/parameter/parameter.component';
import { ParamsComponent } from './maint/params/params.component';
import {AgentgroupsComponent} from './maint/agentgroups/agentgroups.component';
import {CommissiontableComponent} from "./maint/commissiontable/commissiontable.component";
import {ExceptionhandlingComponent} from "./maint/exceptionhandling/exceptionhandling.component";
import {ExtractDataComponent} from "./process/extract-data/extract-data.component";
import {GenerateCommissionComponent} from "./process/generate-commission/generate-commission.component";
import {CommissionDetailComponent} from "./process/commission-detail/commission-detail.component";
import {HierarchicalComponent} from "./maint/hierarchical/hierarchical.component";
import {ComparativeComponent} from "./report/comparative/comparative.component";
import {CashCreditComponent} from "./report/cash-credit/cash-credit.component";
import {CashCreditDetailComponent} from "./report/cash-credit-detail/cash-credit-detail.component";
import { ExceedanceRuleComponent } from './maint/exceedance-rule/exceedance-rule.component';

const routes: Routes = [
  {
    path: '',
    component: FullComponent,
    //canActivate: [LoginService],
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthService],
        data: {
          title: 'menu_home',
          urls: []
        }
      },
      {
        path: 'users',
        component: UserComponent,
        canActivate: [AuthService],
        data: {
          title: 'menu_users',
          urls: [
            { title: 'menu_crud', url: '/dashboard' },
            { title: 'menu_users'}
          ]
        }
      },
      {
        path: 'agent',
        component: AgentsComponent,
        canActivate: [AuthService],
        data: {
          title: 'menu_agent',
          urls: [
            { title: 'menu_crud', url: '/dashboard' },
            { title: 'menu_agent'}
          ]
        }
      },
      {
        path: 'customergroup',
        component: CustomergroupsComponent,
        canActivate: [AuthService],
        data: {
          title: 'menu_customergroup',
          urls: [
            { title: 'menu_crud', url: '/dashboard' },
            { title: 'menu_sites'}
          ]
        }
      },
      {
        path: 'customer',
        component: CustomersComponent,
        canActivate: [AuthService],
        data: {
          title: 'menu_customer',
          urls: [
            { title: 'menu_crud', url: '/dashboard' },
            { title: 'menu_sitegroups' }
          ]
        }
      },
      {
        path: 'productline',
        component: ProductlinesComponent,
        canActivate: [AuthService],
        data: {
          title: 'menu_productline',
          urls: [
            { title: 'menu_crud', url: '/dashboard' },
            { title: 'menu_units'}
          ]
        }
      },
      {
        path: 'subline',
        component: SublineComponent,
        canActivate: [AuthService],
        data: {
          title: 'menu_subline',
          urls: [
            { title: 'menu_crud', url: '/dashboard' },
            { title: 'menu_subline'}
          ]
        }
      },
      {
        path: 'product',
        component: ProductsComponent,
        canActivate: [AuthService],
        data: {
          title: 'menu_product',
          urls: [
            { title: 'menu_crud', url: '/dashboard' },
            { title: 'menu_product'}
          ]
        }
      },
      {
        path: 'exceedanceRule',
        component: ExceedanceRuleComponent,
        canActivate: [AuthService],
        data: {
          title: 'menu_exceedanceRule',
          urls: [
            { title: 'menu_crud', url: '/dashboard' },
            { title: 'menu_exceedanceRule'}
          ]
        }
      },
      {
        path: 'agenttype',
        component: AgenttypesComponent,
        canActivate: [AuthService],
        data: {
          title: 'menu_agenttype',
          urls: [
            { title: 'menu_crud', url: '/dashboard' },
            { title: 'menu_agenttype'}
          ]
        }
      },
      {
        path: 'hierarchical',
        component: HierarchicalComponent,
        canActivate: [AuthService],
        data: {
          title: 'menu_profile',
          urls: [
            { title: 'menu_crud', url: '/dashboard' },
            { title: 'menu_profile'}
          ]
        }
      },
      {
        path: 'parameters',
        component: ParameterComponent,
        canActivate: [AuthService],
        data: {
          title: 'parameters',
          urls: [
            { title: 'menu_crud', url: '/dashboard' },
            { title: 'parameters'}
          ]
        }
      },
      {
        path: 'params/:id',
        component: ParamsComponent,
        canActivate: [AuthService],
        data: {
          title: 'menu_title_mant_params',
          urls: [
            { title: 'menu_crud', url: '/cruds' },
            { title: 'menu_title_mant_analysis', url: '/parameters' },
            { title: 'menu_title_mant_params' }
          ]
        }
      },
      {
        path: 'agentgroups',
        component: AgentgroupsComponent,
        canActivate: [AuthService],
        data: {
          title: 'menu_agent_groups',
          urls: [
            { title: 'menu_crud', url: '/dashboard' },
            { title: 'menu_agent_groups'}
          ]
        }
      },
      {
        path: 'commissiontables',
        component: CommissiontableComponent,
        canActivate: [AuthService],
        data: {
          title: 'menu_commission_tables',
          urls: [
            { title: 'menu_crud', url: '/dashboard' },
            { title: 'menu_commission_tables'}
          ]
        }
      },
      {
        path: 'exceptionhandling',
        component: ExceptionhandlingComponent,
        canActivate: [AuthService],
        data: {
          title: 'menu_exception_handling',
          urls: [
            { title: 'menu_crud', url: '/dashboard' },
            { title: 'menu_exception_handling'}
          ]
        }
      },
      {
        path: 'extractdata',
        component: ExtractDataComponent,
        canActivate: [AuthService],
        data: {
          title: 'menu_get_data',
          urls: [
            { title: 'menu_crud', url: '/dashboard' },
            { title: 'menu_get_data'}
          ]
        }
      },
      {
        path: 'commissionprocess',
        component: GenerateCommissionComponent,
        canActivate: [AuthService],
        data: {
          title: 'menu_commission_process',
          urls: [
            { title: 'menu_crud', url: '/dashboard' },
            { title: 'menu_commission_process'}
          ]
        }
      },
      {
        path: 'commissionprocess/:id',
        component: GenerateCommissionComponent,
        canActivate: [AuthService],
        data: {
          title: 'menu_commission_process',
          urls: [
            { title: 'menu_crud', url: '/dashboard' },
            { title: 'menu_commission_process'}
          ]
        }
      },
      {
        path: 'commissiondetail/:id',
        component: CommissionDetailComponent,
        canActivate: [AuthService],
        data: {
          title: 'menu_title_agent_detail',
          urls: [
            { title: 'menu_crud', url: '/cruds' },
            { title: 'menu_title_mant_analysis', url: '/commissionprocess' },
            { title: 'menu_title_agent_detail' }
          ]
        }
      },
      {
        path: 'comparative',
        component: ComparativeComponent,
        canActivate: [AuthService],
        data: {
          title: 'menu_comparative',
          urls: [
            { title: 'menu_crud', url: '/dashboard' },
            { title: 'menu_comparative'}
          ]
        }
      },
      {
        path: 'cashcredit',
        component: CashCreditComponent,
        canActivate: [AuthService],
        data: {
          title: 'menu_cashcredit',
          urls: [
            { title: 'menu_crud', url: '/dashboard' },
            { title: 'menu_cashcredit'}
          ]
        }
      },
      {
        path: 'cashcreditdetail/:id',
        component: CashCreditDetailComponent,
        canActivate: [AuthService],
        data: {
          title: 'menu_title_agent_detail',
          urls: [
            { title: 'menu_crud', url: '/cruds' },
            { title: 'menu_title_mant_analysis', url: '/commissionprocess' },
            { title: 'menu_title_agent_detail' }
          ]
        }
      }
      // end
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class Ca2RoutingModule { }
