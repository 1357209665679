import { Component, OnInit } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ApiService} from '../../providers/main/api.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { Storage } from '@ionic/storage';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-base-crud',
  templateUrl: './base-crud.component.html',
  styleUrls: ['./base-crud.component.css']
})
export class BaseCrudComponent implements OnInit {
  public rows: any;
  public rawdata: any;
  public create_url: any;
  public read_url: any;
  public update_url: any;
  public delete_url: any;
  public currentcountry: any;
  public isApprover:any;
  public profile:any;
  public currentUser: any;

  public reload = new BehaviorSubject(false);

  public c_obj: any;
  public u_obj: any;
  public i_obj: any;

  public loadingList = false;
  public isCreating = false;
  public isUpdating = false;

  page:any;
  pageTotal:any;
  pageSize:any;

  search:any;
  orderKey:any;
  orderDesc:any;
  search_fields:any;

  f1_key:string;
  f2_key:string;
  f3_key:string;

  f1_val:string;
  f2_val:string;
  f3_val:string;

  translations:any;

  //filter to send
  public showFilters = false;
  public f_datefrom:any;
  public f_dateto:any;
  public f_custom1:any;


  constructor(
    public srv: ApiService,
    public modalService: NgbModal,
    public storage: Storage,
    public router: Router,
    public translate?: TranslateService
  ) {
    this.rows = [];
    this.rawdata = [];
    this.create_url = '';
    this.read_url = '';
    this.update_url = '';
    this.delete_url = '';
    this.currentcountry='';
    this.currentUser='';
    //pagination
    this.page = 1;
    this.pageTotal = 0;
    this.pageSize = 15;

    this.c_obj = {};
    this.u_obj = {};
    this.i_obj = {};

    this.search = '';
    this.orderKey = null;
    this.orderDesc = true;
    //should reference  only to string fields
    this.search_fields = [];
    this.f1_key = 'CompName';
    this.f2_key = 'SitName';
    this.f3_key = 'status';
    this.f1_val = '';
    this.f2_val = '';
    this.f3_val = '';

    this.f_datefrom = null;
    this.f_dateto = null;
    this.f_custom1 = null;
    storage.get('current_country').then(x => {
      this.currentcountry = x;
    });
    storage.get('user_name').then(x=> {
      this.currentUser = x;
    });
  }

  ngOnInit() {
    this.translate.getTranslation(this.translate.currentLang)
      .subscribe((translations) => {
        this.translations = translations;
        //console.log(translations);
      });
  }

  public clearData(){
    this.rows = [];
    this.c_obj = {};
    this.u_obj = {};
  }

  public getList(){
    var qst = this.read_url;
    this.loadingList = true;
    this.srv.getCall(qst).subscribe(x => {
      this.loadingList = false;
      if(x.success){
        this.rawdata = x.val;
        this.rows = x.val;
        this.page = 1;

        if( Array.isArray(this.rows) ){
          this.pageTotal = this.rows.length;
        }
      }
    });
  }

  public getListPost(obj?:any){
    if( obj == undefined ){
      obj = {};
    }

    var qst = this.read_url;
    this.loadingList = true;
    this.srv.postCall(qst, obj).subscribe(x => {
      this.loadingList = false;
      if(x.success){
        this.rawdata = x.val;
        this.rows = x.val;
        this.page = 1;

        if( Array.isArray(this.rows) ){
          this.pageTotal = this.rows.length;
        }
      }
    });
  }

  translateMessage(str:any){
    let msg = '';

    if( this.translations[str+''] != undefined ){
      msg = this.translations[str+''];
    }

    return msg;
  }

  public createItem(content:any, fields:any){
    var qst = this.create_url;
    this.isCreating = true;

    this.srv.postCall(qst, fields).subscribe(x => {
      this.isCreating = false;
      let msg = this.translateMessage(x.msg);

      if(x.success){
        this.reload.next(true);
        this.closeModal(content);
        Swal.fire('¡Creado!', msg, 'success');
      }
      else{
        Swal.fire(msg, '', 'error');
      }
    });
  }

  public updateItem(content:any, fields:any){
    var qst = this.update_url;
    this.isUpdating = true;

    this.srv.postCall(qst, fields).subscribe(x => {
      this.isUpdating = false;
      let msg = this.translateMessage(x.msg);

      if(x.success){
        this.reload.next(true);
        this.closeModal(content);
        Swal.fire('¡Actualizado!', msg, 'success');
      }
      else{
        Swal.fire(msg, '', 'error');
      }
    });
  }

  public deleteItem(obj: any, trans?:any) {
    var qst = this.delete_url;
    //console.log(trans);
    if( trans == undefined ){
      trans = {
        delete: 'Borrar',
        deleted: 'Borrado',
        cancel: 'Cancelar',
        delete_item_title: 'Está seguro que desea eliminar el registro?'
      };
    }

    Swal.fire({
      title: trans.delete_item_title,
      text: '',
      icon: 'warning',
      showCancelButton: true,
      showLoaderOnConfirm: true,
      cancelButtonText: trans.cancel,
      confirmButtonText: trans.delete,
      preConfirm: () => {
        this.srv.postCall(qst, obj).subscribe(x => {
          let msg = this.translateMessage(x.msg);
//debugger;
          if(x.success){
            this.reload.next(true);
            Swal.fire(trans.deleted, msg, 'success');
          }
          else{
            Swal.fire(msg, '', 'error');
          }
        });
      }
    }).then((result) => {
    });
  }

  public openCreateModal(content: any) {
    this.modalService.open(content, { centered: true });
  }

  public openCreateModalLG(content: any) {
    this.modalService.open(content, { size: 'lg', centered: true });
  }

  public openUpdateModal(content: any, obj: any, size?:any) {
    let newobj = JSON.parse(JSON.stringify(obj));
    //console.log(newobj);
    /*const copy = { ...obj };
    this.u_obj = copy;*/
    this.u_obj = newobj;

    if( size == undefined ){
      this.modalService.open(content, { centered: true });
    }
    else{
      this.modalService.open(content, { centered: true, size: size });
    }

  }

  public closeModal(content: any) {
    this.modalService.dismissAll(content);
  }

  public goTo(url, params){
    this.router.navigate([url], {state: params});
  }

  public filterList(fields:any){
    //this.search = event.target.value;
    var resp:boolean;
    if( !Array.isArray(fields) ){
      return;
    }

    if( this.search == '' ){
      this.rows = this.rawdata;
    }
    else{
      let test = this.rawdata.filter(s => {
        resp = false;
        fields.forEach(f => {
          resp = resp || s[f].toLowerCase().includes(this.search.toLowerCase());
        });
        return resp;
      });

      this.rows = test;
    }

    this.pageTotal = this.rows.length;
  }

  public filterAll(){
    var fields = this.search_fields;
    var resp:boolean;
    var dummy:any;

    if( !Array.isArray(fields) ){
      return;
    }

    if( this.search == '' ){
      this.rows = this.rawdata;
    }
    else{
      let test = this.rawdata.filter(s => {
        resp = false;
        fields.forEach(f => {
          if( (s[f] !== null) && (s[f] !== undefined) ){
            resp = resp || String(s[f]).toLowerCase().includes(this.search.toLowerCase());
          }
        });
        return resp;
      });

      this.rows = test;
    }

    if( this.f1_val != '' ){
      /*console.log('btw');
      console.log(this.rows);
      console.log(this.f1_val);*/
      dummy = this.rows.filter(s => {
        return s[this.f1_key] == this.f1_val;
      });

      this.rows = dummy;
    }

    if( this.f2_val != '' ){
      dummy = this.rows.filter(s => {
        return s[this.f2_key] == this.f2_val;
      });

      this.rows = dummy;
    }

    if( this.f3_val != '' ){
      dummy = this.rows.filter(s => {
        return s[this.f3_key] == this.f3_val;
      });

      this.rows = dummy;
    }

    this.pageTotal = this.rows.length;
  }

  public onKeyUpSearch(ev:any){
    var str = ev.target.value;
    if( (str == '') && (this.rows.length != this.rawdata.length) ){
      //console.log('works');
      this.rows = this.rawdata;
      this.pageTotal = this.rows.length;
    }
  }

  public copyObject(obj:any){
    var to:any;
    to = {};
    var keys = [];

    keys = Object.keys(obj);
    if( keys.length > 0 ){
      keys.forEach(x => {
        to[x] = obj[x];
      });
    }

    return to;
  }

  public openInfoModalBig(content: any, obj: any) {
    const copy = { ...obj };
    this.i_obj = copy;
    this.modalService.open(content, { size: 'lg', centered: true});
  }

  public setOrder(str:any){
    this.orderKey = str;
    this.orderDesc = !this.orderDesc;
  }

  public clearSentFilters(){
    this.f_datefrom = null;
    this.f_dateto = null;
    this.f_custom1 = null;
  }

  public getISODate(d:any){
    let fulliso:any;
    //ngbDate count month from 1 but js Date from 0
    fulliso = new Date(d['year'], parseInt(d['month'])-1, d['day']);
    fulliso = fulliso.toISOString().substring(0, 10);
    return fulliso;
  }

}
