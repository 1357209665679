import { Component, OnInit } from '@angular/core';
import {AuthB2cService} from "../../../providers/account/authb2c.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-auth-callback',
  templateUrl: './auth-callback.component.html',
  styleUrls: ['./auth-callback.component.css']
})
export class AuthCallbackComponent implements OnInit {

  constructor(private _authService: AuthB2cService, private router: Router) { }

  ngOnInit(): void {
    //debugger;
    this._authService.finishLogin().then(data1=>{
      /*this._authService.isAuthenticated().then(data=>{
        //if (data){
        setTimeout(()=>{
          if (data){
            this._authService.SetUserData();
            //this._authService.SetPermissionData();

            this.router.navigateByUrl('maps')
          }
        }, 2500);
        //}
      });*/
    })//.then(() => this._authService.getCompaniesData2())
      .then(() => this._authService.isAuthenticated())
      .then(data=>{
        //if (data){
        setTimeout(()=>{
          if (data){
            this._authService.SetUserData();
            //this._authService.getCompaniesData2();
            this._authService.SetPermissionData();

            this.router.navigateByUrl('dashboard');
            /*
            this._authService.getCompanyData().subscribe(c => {
              //console.log(c);
              this._authService.getCommonData().subscribe(d => {

              });
            });//end company data*/
            //this.router.navigateByUrl('dashboard')
          }
        }, 3700);
        //}
      });


  }

}
