import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Global } from '../global';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Storage } from '@ionic/storage';

import * as moment from 'moment-timezone';
import {User} from "oidc-client";
import {AuthB2cService} from "./authb2c.service";

// max time for api requests
const PERMISSIONS_KEY = 'permissions';
@Injectable({
  providedIn: 'root'
})
export class AuthService implements CanActivate {

  private url: any;
  public cont: number;
  momentjs: any = moment;

  user: User | null = null;
  tokenExpired = false;

  pages = [
    {id: 'pdi', name: 'HA_PATHOLOGICAL_ANALYSIS'},
    {id: 'sampling', name: 'HA_SAMPLING'},
    {id: 'sapproval', name: 'HA_SAMPLING_APPROVE'},
    {id: 'sinput', name: 'HA_SAMPLING_INPUT'},
    {id: 'analysis_group', name: 'HA_ANALYSIS_GROUP'},
    {id: 'companies', name: 'HA_COMPANY'},
    {id: 'countries', name: 'HA_COUNTRY'},
    {id: 'laboratories', name: 'HA_LABORATORY_ENTITY'},
    {id: 'prodgroups', name: 'HA_PRODUCTION_GROUP'},
    {id: 'samples', name: 'HA_SAMPLE'},
    {id: 'sample_types', name: 'HA_SAMPLE_TYPE'},
    {id: 'sample_locations', name: 'HA_SAMPLE_LOCATION'},
    {id: 'sample_locinstances', name: 'HA_SAMPLE_LOCATION_INSTANCE'},
    {id: 'shrimp_sector', name: 'HA_SHRIMP_SECTOR'},
    {id: 'skretting_site', name: 'HA_SKRETTING_SITE'},
    {id: 'skretting_sector', name: 'HA_SKRETTING_SECTOR'},
    {id: 'sites', name: 'HA_SITE'},
    {id: 'extractdata', name: 'CA_GET_SALES'},
    {id: 'commissionprocess', name: 'CA_COMMISSION_PROCESS'},
    {id: 'commissiondetail', name: 'CA_COMMISSION_DETAIL'},
    {id: 'exceptionhandling', name: 'CA_EXCEPTIONS_HANDLING'},
    {id: 'commissiontables', name: 'CA_FINE'},
    {id: 'agentgroups', name: 'CA_AGENTS_GROUP'},
    {id: 'params', name: 'CA_COMMISSION_PARAM'},
    {id: 'parameters', name: 'CA_PARAMETERS'},
    {id: 'users', name: 'CA_USERS'},
    {id: 'agent', name: 'CA_AGENTS'},
    {id: 'customergroup', name: 'CA_CUSTOMER_GROUP'},
    {id: 'customer', name: 'CA_CUSTOMER'},
    {id: 'productline', name: 'CA_PRODUCT_LINE'},
    {id: 'subline', name: 'CA_PRODUCT_SUBLINE'},
    {id: 'product', name: 'CA_PRODUCT'},
    {id: 'exceedanceRule', name: 'CA_EXCEEDANCERULES'},
    {id: 'agenttype', name: 'CA_AGENTS_TYPE'},
    {id: 'hierarchical', name: 'CA_HIERARCHICAL_PROFILE'},
    {id: 'comparative', name: 'CA_COMPARATIVE'},
    {id: 'cashcredit', name: 'CA_CASHCREDIT'},
    {id: 'cashcreditdetail', name: 'CA_CASHCREDIT_DETAIL'},
    {id: 'prochome', name: 'HA_PROC'},
    {id: 'cruds', name: 'HA_MANT'}
  ];

  constructor(
    private _router: Router,
    private _http: HttpClient,
    private storage: Storage/*,
    private api: ApiService*/
    , public authService: AuthB2cService
  ) {
    this.url = Global.url;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const page = state.url;

    return this.storage.get(PERMISSIONS_KEY).then(mods => {
      const permissions = ['dashboard', 'settings'];
      let ipage: any;

      if ( !Array.isArray(mods) ) {
        this.clearData().then(() => {
          this._router.navigate(['/account/auth']);
        });
        return false;
      }

      mods.forEach(i => {
        ipage = this.getPageIdFromModule(i);
        if ( ipage !== false ) {
          permissions.push(ipage);
        }
      });

      /*console.log(page);
      console.log(permissions);*/

      const checker = permissions.filter(p => {
        return page.toLowerCase().includes(p.toLowerCase());
      });



      if ( checker.length > 0 ) {
        return true;
      } else {
        this.clearData().then(() => {
          this._router.navigate(['/account/auth']);
        });

        return false;
      }
    });

    /*return this.refreshToken().toPromise().then(x => {
        console.log(x);
        if( x != false ){
            //permissions check
        }
        else{
            this.clearData().then(() => {
                this._router.navigate(['/authentication/login']);
            });

            return false;
        }
    });*/
    if( navigator.onLine ){

      /**/// Obtener el usuario actual
      this.authService.getUsuario().then(user => {debugger;
        this.user = user;

        // Verificar si el token está próximo a expirar o ya ha expirado
        this.checkTokenExpiry();

        // Renovar el token si es necesario
        this.renewTokenIfNeeded();
      }).catch(error => {
        console.error('Error al obtener el usuario:', error);
      });
    }
  }

  getPageIdFromModule(md) {
    let tmp: any[];
    tmp = this.pages.filter(function(a) {
      return a.name === md;
    });

    if ( tmp.length > 0 ) {
      return tmp[0].id;
    }

    return false;
  }

  getPermissions() {
    return this.storage.get(PERMISSIONS_KEY);
  }
  obtenerEsAprobador(){
    return this.storage.get('is_approver');
  }
  obtenerPerfil(){
    return this.storage.get('position');
  }
  getUser(){
    return this.storage.get('user_name');
  }
  /*public getToken() {
    if (localStorage.getItem('Token') && localStorage.getItem('Refresh')) {
        this.token = localStorage.getItem('Token'), localStorage.getItem('Refresh');
    } else {
        this.token = null;
    }
  }

  public log() {
    return (this.token != null) ? true : false;
  }*/

  public login(data: any) {
    //debugger;
    const params = JSON.stringify(data);
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.post(`${this.url}auth/authenticate`, params, { headers });
  }
  public saveCountry(data: any){
    const params =JSON.stringify(data);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    return this._http.post(`${this.url}auth/Update`, params, { headers });
  }

  public getPinCode(email: any) {
    // https://aquasim.skretting.com/
    const aq_url = 'https://az-aquasimuat.skretting.com/';
    const aq_apptoken = '{22261112-BC9B-4042-A9D6-5B9AEC292F85}';

    const params = {
      servicetoken: aq_apptoken,
      email: email
    };

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.post(`${aq_url}services/api/v1.0/passwordreset/GetPincode`, params, { headers });
  }

  public resetPwd(email: any, pin: any, pwd: any) {
    // https://aquasim.skretting.com/
    const aq_url = 'https://az-aquasimuat.skretting.com/';
    const aq_apptoken = '{22261112-BC9B-4042-A9D6-5B9AEC292F85}';

    const params = {
      servicetoken: aq_apptoken,
      email: email,
      pincode: pin,
      newpassword: pwd
    };

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.post(`${aq_url}services/api/v1.0/passwordreset/resetpassword`, params, { headers });
  }

  public saveData(data: any) {
    this.cont = 0;
    const permissions = [];
    // tslint:disable-next-line:variable-name
    const my_companies: Array<any> = [];
    Object.keys(data['Permissions']['result']).map(function(key) {
      return [(data['Permissions']['result'])[key]];
    });

    if ( (data['Permissions'] !== undefined) && (data['Permissions']['result'] !== undefined) ) {
      if ( Array.isArray(data['Permissions']['result']) ) {
        const companies = data['Permissions']['result'];

        companies.forEach(x => {
          // console.log(x);
          my_companies.push({name: x.cmpName, id: x.cmpID});
          x.Modules.forEach(y => {
            if ( permissions.indexOf(y.Name) < 0 ) {
              permissions.push(y.Name);
            }
          });
        });
      }
    }

    const promises =
      [
        this.storage.set('username', data['FullName']),
        this.storage.set('token', data['TokenCommissionalizerAssistant']),// HealthAdvisor
        this.storage.set('refresh', data['RefreshTokenCommissionalizerAssistant']),
        this.storage.set('token_aquasim', data['TokenAquasym']),
        this.storage.set('refreshtoken_aquasim', data['RefreshTokenAquasym']),
        this.storage.set('current_country', data['CurrentCountry']),
        this.storage.set('is_approver', data['IsApprover']),
        this.storage.set('position', data['Position']),
        this.storage.set('user_name', data['Username']),
        this.storage.set('permissions', permissions),
      ];

    return Promise.all(promises);
  }


  public clearData() {
    return this.storage.clear();
  }
  checkTokenExpiry(): void {debugger;
    if (this.user && this.user.expires_in) {
      const expiryTime = new Date(this.user.expires_at);
      const currentTime = new Date();

      // Verificar si el token ya ha expirado
      if (expiryTime <= currentTime) {
        this.tokenExpired = true;
        return;
      }

      // Definir un margen de tiempo antes de la expiración para considerar "próximo a expirar"
      const marginSeconds = 60; // Por ejemplo, 60 segundos antes de la expiración

      // Verificar si el token está próximo a expirar
      if (expiryTime.getTime() - currentTime.getTime() <= marginSeconds * 1000) {
        this.tokenExpired = true;
      }
    }
  }
  renewTokenIfNeeded(): void {debugger;
    if (this.tokenExpired) {
      // Renovar el token
      this.authService.renewToken().then(() => {
        console.log('Token renovado con éxito.');

        // Obtener el usuario actualizado
        return this.authService.getUser();
      }).then(user => {
        this.user = user;

        // Resetear el estado de tokenExpired
        this.tokenExpired = false;
      }).catch(error => {
        console.error('Error al renovar el token:', error);
      });
    }
  }
}
