import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cash-credit-detail',
  templateUrl: './cash-credit-detail.component.html',
  styleUrls: ['./cash-credit-detail.component.css']
})
export class CashCreditDetailComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
