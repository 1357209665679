import { Component, OnInit } from '@angular/core';
import {BaseCrudComponent} from "../../base-crud/base-crud.component";
import {ApiService} from "../../../providers/main/api.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Storage} from "@ionic/storage";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import Swal from "sweetalert2";
import * as XLSX from 'xlsx';
import {catchError, mergeMap} from "rxjs/operators";
import {concat, of} from "rxjs";
@Component({
  selector: 'app-commission-detail',
  templateUrl: './commission-detail.component.html',
  styleUrls: ['./commission-detail.component.css']
})
export class CommissionDetailComponent extends BaseCrudComponent {
  id:any;
  description:any;
  agentname:any;
  period:any;
  loadingRequired = false;
  listAgr:any;
  translations:any;
  currentcountry: any;
  types = [
    {id:1, name:'par_type_manual'},
    {id:2, name:'par_type_fixed'},
    {id:3, name:'par_type_formula'},
    {id:4, name:'par_type_range'},
    {id:5, name:'par_type_richtext'}
  ];
  savevalue_url:string;
  periodList:any;
  /*name of the excel-file which will be downloaded. */
  fileName= 'ExcelSheet.xlsx';
  operands = ['+', '-', '/', '*', '(', ')'];
  numbers = [0, 1, 2 ,3, 4, 5, 6, 7, 8, 9];
  displayedColumns2: string[] = []
  constructor(
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService
  ) {
    super(srv, modalService, storage, router);
    this.id = route.snapshot.params.id;

    this.read_url = 'CommissionResult/GetListDetailByAgent';//'+this.id;
    this.create_url = 'Parameter/Create';
    this.savevalue_url = 'Parameter/SaveValue';
    this.update_url = 'Parameter/Update';
    this.delete_url = 'Parameter/LogicalDelete';
    this.search_fields = ['IdInvoice', 'Customer'];

    this.c_obj['TotalGlobal'] = 0;
    //formula create init
    this.clearCreateObject();
    storage.get('current_country').then(x => {
      this.currentcountry = x;
    });
    this.reload.subscribe(val => {
      if( val ){
        super.clearData();
        this.getList();
      }
    });

  }
  ngOnInit() {
    this.srv.getProfile().then(x=>{
      this.profile = x;
    });
    this.translate.getTranslation(this.translate.currentLang)
      .subscribe((translations) => {
        this.translations = translations;
        super.ngOnInit();
        var batch = [];
        batch.push(this.getPeriodsAprove());
        this.loadingList = true;
        concat(...batch).subscribe({
          complete: () => {
            this.loadingList = false;
            if (!(this.id === undefined)){
              var periodArr=this.id.split('-');

              this.c_obj['agrYear']=periodArr[0]+'-'+periodArr[1];
              this.c_obj['IdAgent']=periodArr[2];
            }else{
              //this.c_obj['agrYear']=periodArr[0]+'-'+periodArr[1];
              //this.c_obj['IdAgent']=periodArr[2];
            }
            this.getList();
          }
        });
      });
  }
  clearCreateObject(){
    this.c_obj = {};
    this.c_obj['form'] = [];
    this.c_obj['value_3'] = '';
    this.c_obj['parIsEditable'] = true;
    this.c_obj['parIsRequired'] = true;
    this.c_obj['parShowInReport'] = false;
    this.c_obj['parHidden'] = false;
  }
  public createItem(content:any, form:any){
    var validated = true;

    if( form == undefined ){
      form = {};
    }

    /*validated = this.validateCreateInfo(form);
    if( !validated ){
      Swal.fire(this.translations['msg_all_required'], '', 'error');
      return;
    }

    var types = [2, 3, 4];
    form['pvalueInfo'] = null;
    var next = true;

    types.forEach(t => {
      if( (form['type'] == t) && ((form['value_'+t] == undefined) || (form['value_'+t] == '')) ){
        Swal.fire(this.translations['msg_all_required'], '', 'error');
        next = false;
        return;
      }

      if( form['type'] == t ){
        form['pvalueInfo'] = form['value_'+t];
      }
    });

    if( !next ){
      return;
    }

    if( form['type'] != 3 ){
      form['pvalueFactors'] = '';
      form['FormulaParameter'] = null;
    }*/

    var to:any;
    to = {};

    to['IdParameter'] = this.id;
    to['Name'] = form['Name'];
    to['IsActive'] ='A';
    /*to['parCode'] = form['parCode'];
    to['parSequence'] = form['parSequence'];
    to['parIsEditable'] = form['parIsEditable'];
    to['parIsRequired'] = form['parIsRequired'];
    to['parShowInReport'] = form['parShowInReport'];
    to['parHidden'] = form['parHidden'];
    to['parIsManualType'] = (form['type'] == 1) ? true : false;
    to['parIsFixedType'] = (form['type'] == 2) ? true : false;
    to['parIsFormulaType'] = (form['type'] == 3) ? true : false;
    to['parIsRangeType'] = (form['type'] == 4) ? true : false;
    to['parIsRichTextType'] = (form['type'] == 5) ? true : false;
    to['parRangeMin'] = form['parRangeMin'];
    to['parRangeMax'] = form['parRangeMax'];*/

    var qst = this.create_url;
    this.isCreating = true;

    this.srv.postCall(qst, to).subscribe(x => {
      this.isCreating = false;
      if(x.success){
        //this.isCreating = true;
        this.c_obj = {};
        this.getList();
        this.closeModal(content);
        /*this.saveValue(x.val['parId'], form).subscribe(y => {
          this.isCreating = false;
          this.c_obj = {};

          if(!y.success){
            Swal.fire(y.msg, '', 'error');
          }

          this.getList();
          this.closeModal(content);
        });*/
      }//end success
      else{
        Swal.fire(x.msg, '', 'error');
        //this.closeModal(content);
      }
    });
  }

  public saveValue(id:any, obj:any){
    var qst = this.savevalue_url;
    var to:any;
    to = {};
    to['pvalueParameterId'] = id;
    to['pvalueInfo'] = obj['pvalueInfo'];
    to['pvalueFactors'] = obj['pvalueFactors'];
    to['FormulaParameter'] = obj['FormulaParameter'];

    return this.srv.postCall(qst, to);
  }

  public updateItem(content:any, form:any){
    var validated = true;

    if( form == undefined ){
      form = {};
    }

    /*validated = this.validateCreateInfo(form);
    if( !validated ){
      Swal.fire(this.translations['msg_all_required'], '', 'error');
      return;
    }

    var types = [2, 3, 4];
    form['pvalueInfo'] = null;
    var next = true;

    types.forEach(t => {
      if( (form['type'] == t) && ((form['value_'+t] == undefined) || (form['value_'+t] == '')) ){
        Swal.fire(this.translations['msg_all_required'], '', 'error');
        next = false;
        return;
      }

      if( form['type'] == t ){
        form['pvalueInfo'] = form['value_'+t];
      }
    });

    if( !next ){
      return;
    }

    if( form['type'] != 3 ){
      form['pvalueFactors'] = '';
      form['FormulaParameter'] = null;
    }*/

    var to:any;
    to = form;//to = {};
    /*to['parId'] = form['parId'];
    to['parSequence'] = form['parSequence'];
    to['parAnalysisId'] = this.id;
    to['parCode'] = form['parCode'];
    to['parName'] = form['parName'];
    to['parIsEditable'] = form['parIsEditable'];
    to['parIsRequired'] = form['parIsRequired'];
    to['parShowInReport'] = form['parShowInReport'];
    to['parHidden'] = form['parHidden'];
    to['parIsManualType'] = (form['type'] == 1) ? true : false;
    to['parIsFixedType'] = (form['type'] == 2) ? true : false;
    to['parIsFormulaType'] = (form['type'] == 3) ? true : false;
    to['parIsRangeType'] = (form['type'] == 4) ? true : false;
    to['parIsRichTextType'] = (form['type'] == 5) ? true : false;
    to['parRangeMin'] = form['parRangeMin'];
    to['parRangeMax'] = form['parRangeMax'];*/

    var qst = this.update_url;
    this.isUpdating = true;

    this.srv.postCall(qst, to).subscribe(x => {
      this.isUpdating = false;
      if(x.success){
        this.getList();
        this.closeModal(content);
        Swal.fire(this.translations['updated'], '', 'success');
        /*this.isUpdating = true;
        this.saveValue(x.val['parId'], form).subscribe(y => {
          this.isUpdating = false;
          this.c_obj = {};

          if(!y.success){
            Swal.fire(y.msg, '', 'error');
          }

          this.getList();
          this.closeModal(content);
        });*/
      }//end success
      else{
        Swal.fire(x.msg, '', 'error');
        //this.closeModal(content);
      }
    });
  }

  validateCreateInfo(fields:any){
    let mygroup:any = {};
    /*mygroup = new FormGroup({
      'parCode': new FormControl(fields.parCode, [Validators.required]),
      'parName': new FormControl(fields.parName, [Validators.required]),
      'parSequence': new FormControl(fields.parSequence, [Validators.required]),
      'type': new FormControl(fields.type, [Validators.required])
    });*/

    return mygroup.valid;
  }

  public getList(){
    var qst = this.read_url+'/'+this.c_obj['agrYear']+'-'+this.c_obj['IdAgent'];
    this.loadingList = true;
    this.srv.getCall(qst).subscribe(x => {
      this.loadingList = false;

      if(x.success){
        x.val.forEach(y => {
          let counter = 1;
          let temptypes = ['parIsManualType', 'parIsFixedType', 'parIsFormulaType', 'parIsRangeType', 'parIsRichTextType'];
          temptypes.forEach(t => {
            if( y[t] ){
              y['type'] = counter;
              y['type_obj'] = this.types.filter(s => {
                return s.id == counter;
              })[0];
              //set values saved in pvalueInfo
              y['value_'+counter] = y['Formula'];
            }
            counter++;
          });

          if( (y['pvalueFactors'] != null) && (y['pvalueFactors'] != '') ){
            //console.log(y['pvalueFactors']);
            y['form'] = JSON.parse(y['pvalueFactors']);
          }
          else{
            y['form'] = [];
          }
          //this.description=y['ParameterGroupName'];
          this.agentname=y['AgentName'];
          this.period=y['Period'];
          this.c_obj['agrYear']=this.period;
        });
        this.c_obj['TotalGlobal']=x.val.filter(item => item.Valtota != 0)
          .reduce((sum, current) => sum + current.Valtota, 0);
        this.rawdata = x.val;
        this.rows = x.val;
   
        this.page = 1;
        for( let v in x.val[0]){
          this.displayedColumns2.push(v);
        }
        if( Array.isArray(this.rows) ){
          this.pageTotal = this.rows.length;
        }
      }
    });
  }

  /*public openUpdateModal(content: any, obj: any) {
    const copy = { ...obj };
    this.c_obj = copy;
    //this.formulaToString(obj['form']);
    //console.log(obj);
    this.modalService.open(content, { centered: true });
  }*/

  public openCreateModal(content: any) {
    this.clearCreateObject();
    super.openCreateModal(content);
  }

  resetFormula(){
    this.c_obj['form'] = [];
    this.c_obj['value_3'] = '';
    this.c_obj['pvalueFactors'] = '';
    this.c_obj['FormulaParameter'] = null;
  }

  formulaToString(obj:any){
    var str = '';
    var sentparams = [];

    obj.forEach(x => {
      if( x.param ){
        str += '<' + x.val + '>';
        sentparams.push({formpParamId: x.id});
      }
      else{
        str += x.val;
      }
    });

    this.c_obj['value_3'] = str;
    this.c_obj['pvalueFactors'] = JSON.stringify(obj);
    this.c_obj['FormulaParameter'] = sentparams;
  }
  exportexcel2(): void
  {
    /* table id is passed over here */
    let element = document.getElementById('excel-table');

    debugger;
    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);


    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    /*ws['!ref'] = XLSX.utils.encode_range({
      s: { c: 0, r: 0 },
      e: { c: 3, r: 1 + 20 }
    });
    ws['C6'] = { f: 'SUM(C2:C5)' };
    ws['B6'] = { v: 'Total' };*/

    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);

  }
  exportexcel(): void
  {

    /* table id is passed over here */
    let element = document.getElementById('excel-table');
    //const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
    /* generate workbook and add the worksheet */

    const edata: any= [];
    const udt: any ={
      data: [
      ],
      skipHeader: true
    }
    //const udt: any ={};
    this.rows.forEach(row=>{
      var obj = {};

      this.displayedColumns2.forEach(x=>{
        if (x!=='AgentName' && x != 'Period' && x!= 'form')
          obj[this.translate.instant(x)] = row[x];
      });
      /**/
      udt.data.push(obj);
    });
    var obj1 = {};
    udt.data.push(obj1);
    edata.push(udt);
    //this.c_obj['TotalGlobal']
    var cell= this.rows.length + 2;
    var ce=this.rows.length + 1;
    debugger;
    const ws: XLSX.WorkSheet=XLSX.utils.json_to_sheet(edata[0].data);
    ws['I'+cell] = { v:  this.c_obj['TotalGlobal'].toFixed(2)};
    ws['A'+cell] = { v: 'Total' };

    /* new format */
    var fmt = "0.00";
    /* change cell format of range B2:D4 */
    var range = { s: {r:1, c:2}, e: {r:ce, c:8} };
    for(var R = range.s.r; R <= range.e.r; ++R) {
      for(var C = range.s.c; C <= range.e.c; ++C) {
        var cell = ws[XLSX.utils.encode_cell({r:R,c:C})];
        if(!cell || cell.t != 'n') continue; // only format numeric cells
        cell.z = fmt;
      }
    }

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Detalle');
    /* save to file */
    XLSX.writeFile(wb, 'Reporte.xlsx');
  }
  public getPeriodsAprove(){
    let url = 'CommissionResult/GetCommissionApprovedList';
    return this.srv.getCall(url).pipe(
      mergeMap(data => {
        let mydata:any = {success: false, msg:'', val:[]};
        mydata = data;
        //debugger;
        if(mydata.success == true ){
          this.periodList = mydata.val;
          return of(true);
        }
        else{
          return of(false);
        }
      }),
      //timeout(MAX_TIME),
      catchError(data => of(false))
    );
  }
}
