import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


import {AuthGuard} from './providers/auth.guard';
import {BlankComponent} from './ca2/layouts/blank/blank.component';
import {AuthCallbackComponent} from "./ca2/redirectS/auth-callback/auth-callback.component";
import {SilentRefreshComponent} from "./ca2/redirectS/silent-refresh/silent-refresh.component";

export const routes: Routes = [
  {
    path: '',
    component: BlankComponent,
    children: [
      { path: '', redirectTo: '/account/auth', pathMatch: 'full' },
      { path: 'callback', component: AuthCallbackComponent },
      { path: 'silent-refresh', component: SilentRefreshComponent },
      {
        path: 'account',
        canActivate: [AuthGuard],
        loadChildren:
          () => import('./account/account.module').then(m => m.AccountModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
